<!-- src/components/GA4TrackingCodeCard.vue -->
<template>
  <b-card class="mb-3">
    <template #header>
      <div class="d-flex align-items-center">
        {{ $t('manage.ga4TrackingCode') }}
        <a href="https://support.google.com/analytics/answer/9539598"
           target="_blank"
           class="ms-1 text-muted"
           title="Learn more about GA4 Tracking Code">
          <i class="bi-question-circle"></i>
        </a>
      </div>
    </template>
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
      <div class="mb-2 mb-md-0 mr-md-2">
        {{ ga4TrackingId || $t('manage.noGa4TrackingCodeSet') }}
      </div>
      <b-button @click="toggleChangeGA4TrackingCode" variant="primary">
        {{ ga4TrackingId ? $t('manage.change') : $t('manage.set') }}
      </b-button>
    </div>
    <div v-if="showUpdateGA4TrackingCode" class="mt-3">
      <b-form-input
          v-model="newGA4TrackingId"
          :state="ga4TrackingIdState"
          :placeholder="$t('manage.enterGA4TrackingCode')"
          class="mt-2"
          @input="validateGA4TrackingId"
      ></b-form-input>
      <b-form-text id="ga4-tracking-id-help">{{ $t('manage.ga4TrackingCodeExample') }}</b-form-text>
      <b-form-invalid-feedback :state="ga4TrackingIdState">
        {{ $t('manage.invalidGA4TrackingCode') }}
      </b-form-invalid-feedback>
      <b-form-checkbox v-model="clearGA4TrackingCode" class="mt-2">
        {{ $t('manage.clear') }}
      </b-form-checkbox>
      <b-button
          @click="changeGA4TrackingCode"
          :disabled="!isValidGA4TrackingId && !clearGA4TrackingCode"
          variant="success"
          class="mt-2"
      >
        {{ clearGA4TrackingCode ? $t('manage.clear') : $t('manage.submit') }}
      </b-button>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'GA4TrackingCodeCard',
  props: {
    ga4TrackingId: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: null
    },
    alias: {
      type: String,
      default: ''
    },
    manageCode: {
      type: String,
      default: ''
    }
  },
  emits: ['update:ga4TrackingId', 'error', 'success', 'loading'],

  data() {
    return {
      showUpdateGA4TrackingCode: false,
      newGA4TrackingId: '',
      clearGA4TrackingCode: false,
      isValidGA4TrackingId: false,
    };
  },
  computed: {
    ga4TrackingIdState() {
      return this.newGA4TrackingId ? this.isValidGA4TrackingId : null;
    },
  },
  methods: {
    toggleChangeGA4TrackingCode() {
      this.showUpdateGA4TrackingCode = !this.showUpdateGA4TrackingCode;
      if (this.showUpdateGA4TrackingCode) {
        this.newGA4TrackingId = '';
        this.clearGA4TrackingCode = false;
      }
    },
    validateGA4TrackingId() {
      const ga4Regex = /^G-[A-Z0-9]{10}$/;
      this.isValidGA4TrackingId = ga4Regex.test(this.newGA4TrackingId);
    },
    async changeGA4TrackingCode() {
      if (!this.isValidGA4TrackingId && !this.clearGA4TrackingCode) {
        this.$emit('error', this.$t('manage.invalidGA4TrackingCode'));
        return;
      }

      try {
        const requestBody = {
          alias: this.alias,
          newGA4TrackingId: this.clearGA4TrackingCode ? null : this.newGA4TrackingId,
          ...(this.userId ? { userId: this.userId } : { manageCode: this.manageCode }),
        };

        const response = await fetch(`https://workers.miniurl.com/changeGA4TrackingCode`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...(this.userId && { 'X-User-Id': this.userId })
          },
          body: JSON.stringify(requestBody)
        });

        if (response.ok) {
          await response.json();
          this.$emit('update:ga4TrackingId', this.clearGA4TrackingCode ? '' : this.newGA4TrackingId);
          this.showUpdateGA4TrackingCode = false;
          this.$emit('success', this.$t(this.clearGA4TrackingCode ? 'manage.clearSuccess' : 'manage.changeSuccess'));
        } else {
          const errorResponse = await response.json();
          this.$emit('error', errorResponse.message || this.$t('manage.changeFailed'));
        }
      } catch (error) {
        console.error(error);
        this.$emit('error', this.$t('manage.networkError'));
      } finally {
        this.$emit('loading', false);
        this.clearGA4TrackingCode = false;
        this.newGA4TrackingId = '';
      }
    },
  },
};
</script>