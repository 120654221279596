import { createRouter, createWebHistory } from 'vue-router';

// 使用 require.context 自动导入 views 目录下的所有 .vue 文件
const viewsContext = require.context('@/views', true, /\.vue$/);

// 自动生成路由配置
const routes = viewsContext.keys().map(file => {
    const component = viewsContext(file).default;
    const name = file.replace(/^\.\//, '').replace(/\.vue$/, '');
    const path = `/${name.toLowerCase() === 'home' ? '' : name.toLowerCase()}`;

    return {
        path,
        name,
        component,
        meta: {
            titleKey: `routes.${name.toLowerCase()}.title`,
            descriptionKey: `routes.${name.toLowerCase()}.description`
        }
    };
});

// 添加特殊路由
routes.push({
    path: '/:path',
    name: 'Redirect',
    component: () => import('@/views/UrlRedirect.vue'),
    beforeEnter: (to, from, next) => {
        /^[A-Za-z0-9_-]{4,64}$/.test(to.params.path) ? next() : next({ name: 'Home', query: { error: 'invalid-url' } });
    },
    meta: { titleKey: 'routes.redirect.title' },
});

routes.push({
    path: '/s/:id',
    name: 'ShortUrlRedirect',
    component: () => import('@/views/UrlRedirect.vue'),
});

const createRouterInstance = (i18n) => {
    const router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes,
    });

    router.beforeEach((to, from, next) => {
        if (typeof window !== 'undefined') {
            const { hostname, protocol } = window.location;
            if (hostname.startsWith('www.')) {
                window.location.href = `${protocol}//${hostname.replace('www.', '')}${to.fullPath}`;
                return;
            }
        }
        next();
    });

    router.afterEach((to) => {
        const defaultTitle = 'MiniURL.com - Free URL Shortener & Analytics - Link Shortening | Short Links';
        const defaultDescription = 'Shorten URLs & analytics quickly and easily with MiniURL.com, the free link shortener track clicks. Simplify sharing with compact links.';

        // 使用 nextTick 确保 DOM 更新后再设置标题
        Promise.resolve().then(() => {
            document.title = i18n.global.t(to.meta.titleKey) || defaultTitle;

            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
                metaDescription.setAttribute('content', to.meta.descriptionKey ? i18n.global.t(to.meta.descriptionKey) : defaultDescription);
            }
        });
    });

    return router;
};

export default createRouterInstance;