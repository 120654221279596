<!--FAQ.vue-->
<template>
  <b-container fluid="md" id="faq-app">
    <b-card class="mb-5">
      <h5 class="text-center mb-4">{{ $t('faq.whyChoose') }}</h5>
      <b-row>
        <b-col md="4" v-for="(feature, index) in featureList" :key="index" class="text-center mb-4">
          <i :class="['bi', feature.icon, 'display-6', 'text-primary', 'd-inline-block', 'mb-3']"></i>
          <h6 class="font-weight-bold">{{ $t(feature.title) }}</h6>
          <p class="text-muted small">{{ $t(feature.description) }}</p>
        </b-col>
      </b-row>
    </b-card>

    <h5 class="text-center mb-2">{{ $t('faq.title') }}</h5>
    <b-accordion id="faqAccordion" class="mb-5">
      <b-accordion-item v-for="(item, index) in faqList" :key="index" :title="$t(item.question)">
        <p class="mb-0">{{ $t(item.answer) }}</p>
      </b-accordion-item>
    </b-accordion>

    <!-- Updated Statistics Section -->
    <b-card class="mb-5">
      <h5 class="text-center mb-4">{{ $t('faq.statistics') }}</h5>
      <b-row>
        <b-col md="4" v-for="(stat, index) in statistics" :key="index" class="text-center mb-4">
          <i :class="['bi', stat.icon, 'display-6', 'text-primary', 'd-inline-block', 'mb-3']"></i>
          <h3 class="font-weight-bold">{{ stat.value }}</h3>
          <p class="text-muted">{{ $t(stat.label) }}</p>
        </b-col>
      </b-row>
    </b-card>

    <!-- CTA Section -->
    <b-card class="text-center bg-light mb-5" v-if="!isLoggedIn">
        <h5 class="mb-3">{{ $t('home.CTATitle') }}</h5>
        <p class="mb-4">{{ $t('home.CTADescription') }}</p>
        <b-button variant="primary" :to="{ name: 'user' }">
          {{ $t('home.CTAButtonText') }}
        </b-button>
    </b-card>
  </b-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'FAQ',
  data() {
    return {
      isLoggedIn: false,
      featureList: [
        {
          title: "faq.trustworthy.title",
          description: "faq.trustworthy.description",
          icon: "bi-patch-check"
        },
        {
          title: "faq.fast.title",
          description: "faq.fast.description",
          icon: "bi-lightning-charge"
        },
        {
          title: "faq.analytics.title",
          description: "faq.analytics.description",
          icon: "bi-bar-chart-line"
        },
        {
          title: "faq.aiDriven.title",
          description: "faq.aiDriven.description",
          icon: "bi-robot"
        },
        {
          title: "faq.passwordProtection.title",
          description: "faq.passwordProtection.description",
          icon: "bi-shield-lock"
        },
        {
          title: "faq.expiryDate.title",
          description: "faq.expiryDate.description",
          icon: "bi-calendar-event"
        }
      ],
      faqList: [
        {
          question: "faq.questions.storage.question",
          answer: "faq.questions.storage.answer"
        },
        {
          question: "faq.questions.prohibited.question",
          answer: "faq.questions.prohibited.answer"
        },
        {
          question: "faq.questions.limit.question",
          answer: "faq.questions.limit.answer"
        }
      ],
      statistics: [
        {
          icon: "bi-link-45deg",
          value: "0",
          label: "faq.miniUrls"
        },
        {
          icon: "bi-people",
          value: "0",
          label: "faq.users"
        },
        {
          icon: "bi-calendar3",
          value: "0",
          label: "faq.days"
        }
      ]
    };
  },
  created() {
    this.checkLoginStatus();
    this.fetchStatistics();
    this.calculateDays();
  },
  methods: {
    checkLoginStatus() {
      this.isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    },
    async fetchStatistics() {
      try {
        const response = await axios.post('https://workers.miniurl.com/getStatistics');
        const { total_urls, total_users } = response.data;
        this.statistics[0].value = total_urls.toLocaleString();
        this.statistics[1].value = total_users.toLocaleString();
      } catch (error) {
        console.error('Error fetching statistics:', error);
      }
    },
    calculateDays() {
      const startDate = new Date('2024-07-01');
      const currentDate = new Date();
      const timeDiff = currentDate.getTime() - startDate.getTime();
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      this.statistics[2].value = daysDiff.toLocaleString();
    }
  }
};
</script>