<!-- src/components/StatisticsPanel.vue -->
<template>
  <div>
    <b-card class="mb-3" :header="$t('manage.trafficAnalysis')">
      <b-col class="mb-3">
        <b-tabs content-class="mt-3" v-model="currentTab">
          <b-tab :title="$t('manage.24H')" active>
            <div>
              <line-chart :chart-data="data24Hours" style="height: 200px;"></line-chart>
            </div>
          </b-tab>
          <b-tab :title="$t('manage.7D')">
            <div>
              <line-chart :chart-data="data7Days" style="height: 200px;"></line-chart>
            </div>
          </b-tab>
          <b-tab :title="$t('manage.30D')">
            <div>
              <line-chart :chart-data="data30Days" style="height: 200px;"></line-chart>
            </div>
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col>
        <b-table striped hover :key="currentTab" :items="computedTableData"></b-table>
        <p class="small text-muted text-end">{{ $t('manage.top10CountriesNote') }}</p>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import LineChart from "@/components/LineChart.vue";

export default {
  name: 'StatisticsPanel',
  components: {
    LineChart,
  },
  props: {
    clicks24Hours: Array,
    clicks7Days: Array,
    clicks30Days: Array,
    tableData: Object,
  },
  data() {
    return {
      currentTab: 0,
      data24Hours: {
        labels: this.generateHourLabels(),
        datasets: [{label: '', data: []}]
      },
      data7Days: {
        labels: this.generateDayLabels(7, 'weekday'),
        datasets: [{label: '', data: []}]
      },
      data30Days: {
        labels: this.generateDayLabels(30, 'date'),
        datasets: [{label: '', data: []}]
      },
    };
  },
  computed: {
    totalClicks24Hours() {
      return this.clicks24Hours.reduce((sum, clicks) => sum + clicks, 0);
    },
    totalClicks7Days() {
      return this.clicks7Days.reduce((sum, clicks) => sum + clicks, 0);
    },
    totalClicks30Days() {
      return this.clicks30Days.reduce((sum, clicks) => sum + clicks, 0);
    },
    computedTableData() {
      let data;
      switch (this.currentTab) {
        case 0:
          data = this.tableData.countryClicks24Hours || [];
          break;
        case 1:
          data = this.tableData.countryClicks7Days || [];
          break;
        case 2:
          data = this.tableData.countryClicks30Days || [];
          break;
        default:
          data = [];
          break;
      }
      return data;
    },
  },
  watch: {
    clicks24Hours: {
      handler(newVal) {
        this.data24Hours.datasets[0].data = newVal;
        this.data24Hours.datasets[0].label = `${this.$t('manage.totalClicks')}: ${this.totalClicks24Hours}`;
        this.data24Hours = {...this.data24Hours};
      },
      immediate: true
    },
    clicks7Days: {
      handler(newVal) {
        this.data7Days.datasets[0].data = newVal;
        this.data7Days.datasets[0].label = `${this.$t('manage.totalClicks')}: ${this.totalClicks7Days}`;
        this.data7Days = {...this.data7Days};
      },
      immediate: true
    },
    clicks30Days: {
      handler(newVal) {
        this.data30Days.datasets[0].data = newVal;
        this.data30Days.datasets[0].label = `${this.$t('manage.totalClicks')}: ${this.totalClicks30Days}`;
        this.data30Days = {...this.data30Days};
      },
      immediate: true
    },
  },
  methods: {
    generateHourLabels() {
      let currentDate = new Date();
      let currentHour = currentDate.getHours();

      return Array.from({length: 24}, (_, i) => {
        let hour = (currentHour - i + 24) % 24;
        return `${hour.toString().padStart(2, '0')}:00`;
      }).reverse();
    },
    generateDayLabels(days, format) {
      const today = new Date();
      const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return Array.from({length: days}, (_, i) => {
        const day = new Date(today);
        day.setDate(today.getDate() - (days - 1 - i));
        if (format === 'weekday') {
          return weekday[day.getDay()];
        } else {
          const month = (day.getMonth() + 1).toString().padStart(2, '0');
          const date = day.getDate().toString().padStart(2, '0');
          return `${month}-${date}`;
        }
      });
    },
  },
};
</script>