<!--Home.vue-->
<template>
  <div>
    <b-container fluid="md" class="my-4">
      <!-- URL and Alias Input Row -->
      <b-row class="my-3 align-h-center" align-h="center">
        <b-col cols="12" class="mb-1">
          <div>
            <label for="url-input" class="mb-2 ms-1 fw-bold">{{ $t('home.enterUrl') }}</label>
            <b-form-input
                id="url-input"
                v-model="url"
                :state="getUrlValidationState"
                :placeholder="$t('home.urlPlaceholder')"
                @input="checkUrl"
                class="mb-2"
            ></b-form-input>
            <b-form-invalid-feedback v-if="url && !isValidUrl" class="mt-2">
              {{ $t('home.invalidUrl') }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="url && isValidUrl && !hasProtocol" class="mt-2">
              {{ $t('home.urlProtocol') }}
            </b-form-invalid-feedback>
          </div>
        </b-col>
        <b-col cols="12" class="d-flex align-items-center">
          <b-input-group class="flex-grow-1">
            <b-input-group-prepend>
              <b-input-group-text>https://miniurl.com/</b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="alias" :state="getAliasValidationState" :placeholder="$t('home.aliasPlaceholder')" @input="validateAlias"></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="generateAlias" :disabled="!canGenerateAlias">
                <b-spinner small v-if="isGeneratingAlias"></b-spinner>
                <i v-else class="bi bi-magic"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-spinner v-if="isCheckingAlias" variant="primary" small :label="$t('home.checkingAlias')" class="mx-lg-2"></b-spinner>
        </b-col>
        <b-col cols="12" v-if="aliasSuggestions.length > 1">
          <b-input-group class="w-100">
            <b-input-group-prepend style="flex-shrink: 0;">
              <b-input-group-text style="visibility: hidden;">https://miniurl.com/</b-input-group-text>
            </b-input-group-prepend>
            <div class="flex-grow-1" style="overflow: hidden;">
              <b-list-group>
                <b-list-group-item
                    v-for="(suggestion, index) in aliasSuggestions"
                    :key="index"
                    @click="selectAlias(suggestion)"
                    @mouseover="hover = index"
                    @mouseout="hover = null"
                    :variant="(hover === index || selectedAlias === suggestion) ? 'primary' : ''"
                    style="cursor: pointer;"
                >
                  {{ suggestion }}
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-input-group>
        </b-col>
      </b-row>

      <!-- Advanced Settings Button -->
      <b-row class="mb-2 text-end">
        <b-col>
          <b-button variant="link" class="text-black" @click="toggleAdvancedSettings">
            {{ $t('home.advancedSettings') }} <i class="bi" :class="showAdvancedSettings ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
          </b-button>
        </b-col>
      </b-row>

      <!-- Advanced Settings -->
      <b-row v-if="showAdvancedSettings">
        <b-col cols="12" md="6">
          <b-form-group :label="$t('home.accessPassword')">
            <b-form-input v-model="accessPassword" :placeholder="$t('home.enterPassword')"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="$t('home.expirationDateTime')">
            <input
                type="datetime-local"
                v-model="expirationDateTime"
                class="form-control"
                :placeholder="$t('home.selectDateTime')"
            >
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Button Row -->
      <b-row align-h="center" class="mb-2">
        <b-col cols="12">
          <b-button :disabled="isLoading || !isValidUrl || !hasProtocol" @click="shortenUrl" variant="primary" class="w-100">
            <b-spinner small v-if="isLoading"></b-spinner>
            {{ isLoading ? $t('home.creatingMiniUrl') : $t('home.getMiniUrl') }}
            <i class="bi bi-arrow-right me-2" v-if="!isLoading"></i>
          </b-button>
        </b-col>
      </b-row>

      <!-- Error Message Row -->
      <b-row v-if="errorMessage">
        <b-col>
          <div class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>
        </b-col>
      </b-row>

      <!-- Success Message Row -->
      <b-row v-if="successMessage">
        <b-col>
          <div class="alert alert-success" role="alert">
            {{ successMessage }}
          </div>
        </b-col>
      </b-row>

      <!-- Short URL Display in Card -->
      <b-row align-h="center" class="mt-4" v-if="shortUrl">
        <b-col cols="12">
          <div class="alert alert-success mb-0">
            {{ $t('home.miniUrlReady') }}
          </div>
          <b-input-group class="mt-1">
            <b-form-input v-model="shortUrl" readonly></b-form-input>
            <b-input-group-append>
              <!-- Copy Button -->
              <b-button variant="outline-secondary" @click="copyToClipboard(shortUrl, 'url')">
                <i class="bi" :class="copyUrlSuccess ? 'bi-check-all' : 'bi-copy'" aria-hidden="true"></i>
              </b-button>
              <b-button variant="outline-secondary" @click="handleToggleQrCode">
                <i class="bi bi-qr-code" aria-hidden="true"></i>
              </b-button>
              <!-- Open in New Tab Button -->
              <b-button variant="outline-secondary" @click="openInNewTab">
                <i class="bi bi-box-arrow-up-right" aria-hidden="true"></i>
              </b-button>
              <!-- Social Share Dropdown -->
              <b-dropdown right variant="outline-secondary">
                <template #button-content>
                  <i class="bi bi-share" aria-hidden="true"></i>
                </template>
                <b-dropdown-item @click="shareOnSocialMedia('facebook')">
                  <i class="bi bi-facebook mr-2"></i>
                  Facebook
                </b-dropdown-item>
                <b-dropdown-item @click="shareOnSocialMedia('x')">
                  <i class="bi bi-twitter mr-2"></i>
                  X
                </b-dropdown-item>
                <b-dropdown-item @click="shareOnSocialMedia('whatsapp')">
                  <i class="bi bi-whatsapp mr-2"></i>
                  WhatsApp
                </b-dropdown-item>
                <b-dropdown-item @click="shareOnSocialMedia('linkedin')">
                  <i class="bi bi-linkedin mr-2"></i>
                  LinkedIn
                </b-dropdown-item>
                <b-dropdown-item @click="shareOnSocialMedia('email')">
                  <i class="bi bi-envelope mr-2"></i>
                  {{ $t('home.email') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <!-- QR Code Modal -->
      <b-modal v-model="showQrCode" title="QR Code" hide-footer modal-class="shadow-lg rounded" centered>
        <QRCodeComponent :shortUrl="shortUrl" :fullUrl="url" v-if="showQrCode"></QRCodeComponent>
      </b-modal>

      <!-- Manage Code Display -->
      <b-row align-h="center" class="mt-4" v-if="manageCode">
        <b-col cols="12">
          <div class="alert alert-warning mb-0">
            <b>{{ $t('home.saveManageCode') }}</b>
          </div>
          <b-input-group class="mt-1">
            <b-form-input v-model="manageCode" readonly></b-form-input>
            <b-input-group-append>
              <!-- Copy Button -->
              <b-button variant="outline-secondary" @click="copyToClipboard(manageCode, 'manageCode')">
                <i class="bi" :class="copyManageCodeSuccess ? 'bi-check-all' : 'bi-copy'" aria-hidden="true"></i>
              </b-button>
              <!-- Email Button -->
              <b-button variant="outline-secondary" @click="showEmailModal = true">
                <i class="bi bi-envelope" aria-hidden="true"></i>
              </b-button>
              <!-- Download Button -->
              <b-button variant="outline-secondary" @click="downloadInfo">
                <i class="bi bi-download" aria-hidden="true"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-modal v-model="showEmailModal" :title="$t('home.sendManageCodeEmail')" @ok="sendManageCodeByEmail">
        <b-form-input v-model="emailAddress" :placeholder="$t('home.enterEmail')" type="email"></b-form-input>
        <div class="mt-3" v-if="showEmailModal">
          <vue-turnstile site-key="0x4AAAAAAAeS7aKcYljfP7iS" v-model="token" />
        </div>
      </b-modal>

    </b-container>
  </div>
  <FAQ/>
</template>

<script>
import FAQ from "@/components/FAQ.vue";
import {validateUrl} from "@/utils/tools";
import VueTurnstile from 'vue-turnstile';
import {copyToClipboard, downloadInfo, sendManageCodeByEmail, shareOnSocialMedia} from '@/utils/shareUtils';
import QRCodeComponent from "@/components/QRCodeComponent.vue";

export default {
  components: {
    FAQ,
    VueTurnstile,
    QRCodeComponent,
  },
  data() {
    return {
      url: '',
      alias: '',
      shortUrl: '',
      manageCode: '',
      errorMessage: '',
      successMessage: '',
      isLoading: false,
      isValidUrl: false,
      hasProtocol: false,
      aliasExists: false,
      isAliasValid: null,
      isCheckingAlias: false,
      copyUrlSuccess: false,
      copyManageCodeSuccess: false,
      isGeneratingAlias: false,
      aliasSuggestions: [],
      hover: null,
      selectedAlias: null,
      showQrCode: false,
      qrCodeUrl: '',
      showEmailModal: false,
      emailAddress: '',
      token: '',
      showAdvancedSettings: false,
      accessPassword: '',
      expirationDateTime: '',
      userId: null,
    };
  },
  created() {
    this.userId = localStorage.getItem('userId');
  },
  computed: {
    getUrlValidationState() {
      if (!this.url) return null;
      return this.isValidUrl && this.hasProtocol;
    },
    getAliasValidationState() {
      if (!this.alias) return null;
      return this.isAliasValid && !this.aliasExists;
    },
    canGenerateAlias() {
      return this.isValidUrl && this.hasProtocol;
    }
  },
  methods: {
    toggleAdvancedSettings() {
      this.showAdvancedSettings = !this.showAdvancedSettings;
    },

    checkUrl() {
      const container = document.getElementById('faq-app');
      container.style.display = 'none';
      this.url = this.url.trim();
      const result = validateUrl(this.url);
      this.isValidUrl = result.isValid;
      this.hasProtocol = result.hasProtocol;
      if (this.isValidUrl && this.hasProtocol) {
        this.generateAlias();
      }
    },

    async validateAlias() {
      const aliasPattern = /^[A-Za-z0-9_-]{4,64}$/;
      if (!this.alias) {
        this.isAliasValid = null;
        this.aliasExists = false;
        this.errorMessage = '';
        return;
      }
      if (!aliasPattern.test(this.alias)) {
        this.isAliasValid = false;
        this.errorMessage = 'Alias must be within 4-64 characters and can include letters, numbers, and underscores.';
        return;
      }
      this.isCheckingAlias = true;
      try {
        this.errorMessage = '';
        await this.checkAliasExistence();
      } finally {
        this.isCheckingAlias = false;
      }
    },

    async checkAliasExistence() {
      try {
        const response = await fetch(`https://workers.miniurl.com/checkAlias`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ alias: this.alias || undefined })
        });

        const data = await response.json();

        if (!response.ok) {
          console.error('Failed to check alias existence:', data.error || 'Unknown error');
          this.isAliasValid = false;
          this.errorMessage = data.error || 'Failed to check alias existence.';
          return;
        }

        this.aliasExists = data.exists;
        if (this.aliasExists) {
          this.isAliasValid = false;
          this.errorMessage = data.error || 'This alias is already in use or reserved.';
        } else {
          this.isAliasValid = true;
          this.errorMessage = '';
        }
      } catch (error) {
        console.error('Failed to check alias:', error);
        this.isAliasValid = false;
        this.errorMessage = 'Error checking alias.';
      }
    },

    async generateAlias() {
      if (!this.canGenerateAlias) {
        this.errorMessage = 'Please enter a valid URL with http:// or https:// to generate an alias.';
        return;
      }
      this.isGeneratingAlias = true;
      this.errorMessage = '';
      try {
        const response = await fetch(`https://workers.miniurl.com/aiAlias`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ fullUrl: this.url })
        });
        if (!response.ok) {
          this.errorMessage = 'Failed to fetch aliases';
        }
        const data = await response.json();
        if (data.aliases.length > 0) {
          this.alias = data.aliases[0];
          this.selectedAlias = this.alias;
          this.aliasSuggestions = data.aliases;
          this.validateAlias();
        } else {
          this.aliasSuggestions = [];
        }
      } catch (error) {
        console.error('Error generating aliases:', error);
        this.errorMessage = 'Failed to generate aliases. Please enter manually or leave blank.';
      } finally {
        this.isGeneratingAlias = false;
      }
    },

    selectAlias(alias) {
      this.alias = alias;
      this.selectedAlias = alias;
      this.validateAlias();
    },

    async shortenUrl() {
      this.isLoading = true;
      this.errorMessage = '';
      try {
        const payload = {
          fullUrl: this.url,
          alias: this.alias || undefined,
          accessPassword: this.accessPassword || undefined,
          expirationDate: this.expirationDateTime ? new Date(this.expirationDateTime).toISOString() : undefined,
          userId: this.userId,
        };
        const token = localStorage.getItem('token');
        const headers = {
          'Content-Type': 'application/json',
        };
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }
        const response = await fetch('https://workers.miniurl.com/shortUrls', {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(payload)
        });

        if (!response.ok) {
          const errorData = await response.json();
          this.errorMessage = errorData.error || 'There was an error processing your request';
          return;
        }

        const data = await response.json();
        this.shortUrl = window.location.origin + '/' + data.short;
        this.manageCode = data.manageCode;
        this.aliasSuggestions = [];

        // 检查是否有新的 token
        if (data.newToken) {
          localStorage.setItem('token', data.newToken);
        }


      } catch (error) {
        this.errorMessage = error.message || 'Failed to shorten URL';
        console.error('Failed to shorten URL:', error);
      } finally {
        this.isLoading = false;
      }
    },

    async copyToClipboard(text, type) {
      try {
        await copyToClipboard(text);
        if (type === 'url') {
          this.copyUrlSuccess = true;
          setTimeout(() => this.copyUrlSuccess = false, 2000);
        } else if (type === 'manageCode') {
          this.copyManageCodeSuccess = true;
          setTimeout(() => this.copyManageCodeSuccess = false, 2000);
        }
      } catch (err) {
        console.error('Could not copy text: ', err);
        this.errorMessage = 'Failed to copy. Please try again.';
      }
    },

    openInNewTab() {
      window.open(this.shortUrl, '_blank');
    },

    downloadInfo() {
      downloadInfo(this.shortUrl, this.manageCode);
    },

    handleToggleQrCode() {
      this.showQrCode = !this.showQrCode;
    },

    async sendManageCodeByEmail() {
      if (!this.token) {
        this.errorMessage = 'Please complete the Cloudflare challenge';
        return;
      }

      try {
        this.successMessage = await sendManageCodeByEmail(this.manageCode, this.emailAddress, this.token);
        this.showEmailModal = false;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },

    shareOnSocialMedia(platform) {
      shareOnSocialMedia(platform, this.shortUrl);
    },
  }
};
</script>