<!-- GoogleAuthCallback.vue -->
<template>
  <div>
    <b-container fluid="md" class="my-4">
      <b-row>
        <b-col class="text-center">
          <h5>{{ $t('auth.processingGoogleLogin') }}</h5>
          <b-spinner variant="primary" label="Processing..."></b-spinner>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'GoogleAuthCallback',
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      this.exchangeCodeForToken(code);
    } else {
      console.error('No authorization code found in the URL');
      this.$router.push('/auth');
    }
  },
  methods: {
    async exchangeCodeForToken(code) {
      try {
        const redirectUri = `${window.location.origin}/googleauthcallback`;
        const response = await fetch('https://workers.miniurl.com/googleAuth', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code, redirectUri }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            this.saveUserData(data);
            this.$router.push('/user');
          } else {
            throw new Error(data.message || 'Authentication failed');
          }
        } else {
          throw new Error('Failed to exchange code for token');
        }
      } catch (error) {
        console.error('Error during Google authentication:', error);
        this.$router.push('/auth');
      }
    },
    saveUserData(data) {
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('userId', data.userId);
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify({ email: data.email, id: data.userId }));
    }
  }
}
</script>