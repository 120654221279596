<!--Manage.vue-->
<template>
  <div>
    <b-container fluid="md" class="my-4">
      <!-- URL Management Input Section -->
      <b-row class="my-3">
        <b-col cols="12" class="mt-3">
          <b-form-input
              id="manage-code-input"
              v-model="manageCode"
              type="password"
              :placeholder="$t('manage.enterManageCode')"
          ></b-form-input>
        </b-col>
        <b-col cols="12" class="mt-3">
          <b-button
              @click="manageMiniURL"
              :disabled="isManageButtonDisabled || isLoading"
              variant="primary"
              class="w-100"
          >
            <span v-if="isLoading">
              <b-spinner small type="grow" class="mr-2"></b-spinner>
              {{ $t('manage.loading') }}
            </span>
            <span v-else>
              {{ $t('manage.manageMiniURL') }}
            </span>
          </b-button>
        </b-col>
      </b-row>

      <!-- Management Panel Display -->
      <b-row v-if="showManagementPanel" class="mt-3">
        <!-- Main Card -->
        <b-col cols="12">
          <b-card class="mb-3" :header="$t('manage.trafficAnalysis')">
            <b-col class="mb-3">
              <b-tabs content-class="mt-3" v-model="currentTab">
                <b-tab :title="$t('manage.24H')" active>
                  <div>
                    <line-chart :chart-data="data24Hours" style="height: 200px;"></line-chart>
                  </div>
                </b-tab>
                <b-tab :title="$t('manage.7D')">
                  <div>
                    <line-chart :chart-data="data7Days" style="height: 200px;"></line-chart>
                  </div>
                </b-tab>
                <b-tab :title="$t('manage.30D')">
                  <div>
                    <line-chart :chart-data="data30Days" style="height: 200px;"></line-chart>
                  </div>
                </b-tab>
              </b-tabs>
            </b-col>
            <b-col>
              <b-table striped hover :key="currentTab" :items="computedTableData"></b-table>
              <p class="small text-muted text-end">{{ $t('manage.top10CountriesNote') }}</p>
            </b-col>
          </b-card>
        </b-col>

        <!-- Total Clicks Card -->
        <b-col cols="12" md="4">
          <b-card class="mb-3" :header="$t('manage.totalClicks')">
            <b-card-body class="text-center">
              <h5 class="card-title mb-0">{{ urlData.clicks }}</h5>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Last Clicked Card -->
        <b-col cols="12" md="4">
          <b-card class="mb-3" :header="$t('manage.lastClicked')">
            <b-card-body class="text-center">
              <h5 class="card-title mb-0">{{ formatDateTime(urlData.last_clicked) }}</h5>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Created At Card -->
        <b-col cols="12" md="4">
          <b-card class="mb-3" :header="$t('manage.createdAt')">
            <b-card-body class="text-center">
              <h5 class="card-title mb-0">{{ formatDateTime(urlData.created_at) }}</h5>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- MiniURL Card -->
        <b-col cols="12">
          <b-card class="mb-3" header="MiniURL">
            <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div class="mb-2 mb-md-0 mr-md-2">
                <a :href="'https://miniurl.com/' + urlData.alias" target="_blank" style="margin-right: 10px;">
                  https://miniurl.com/{{ urlData.alias }}
                </a>
                <i class="bi bi-box-arrow-up-right"></i>
              </div>
              <div>
                <b-button @click="showQrCode = true" variant="outline-secondary" class="me-2">
                  <i class="bi bi-qr-code"></i> {{ $t('manage.showQRCode') }}
                </b-button>
                <b-button @click="deleteUrl" variant="danger">{{ $t('manage.delete') }}</b-button>
              </div>
            </div>
          </b-card>
        </b-col>

        <!-- Full URL Card -->
        <b-col cols="12">
          <b-card class="mb-3" :header="$t('manage.fullUrl')">
            <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div class="mb-2 mb-md-0 mr-md-2" style="word-break: break-word;">
                <a :href="urlData.full_url" target="_blank" style="margin-right: 10px;">
                  {{ urlData.full_url }}
                </a>
                <i class="bi bi-box-arrow-up-right"></i>
              </div>
              <b-button @click="toggleChangeUrl" variant="primary">{{ $t('manage.change') }}</b-button>
            </div>
            <!-- Update URL Input -->
            <div v-if="showUpdateInput" class="mt-3">
              <b-form-input
                  v-model="newUrl"
                  :state="getUrlValidationState"
                  :placeholder="$t('manage.enterNewUrl')"
                  class="mt-2"
                  @input="checkUrl"
              ></b-form-input>
              <b-button
                  @click="changeUrl"
                  :disabled="!isValidUrl || !hasProtocol"
                  variant="success"
                  class="mt-2"
              >
                {{ $t('manage.submitNewFullUrl') }}
              </b-button>
              <b-form-invalid-feedback v-if="newUrl && !isValidUrl" class="mt-2">
                {{ $t('manage.enterValidUrl') }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="newUrl && isValidUrl && !hasProtocol" class="mt-2">
                {{ $t('manage.urlMustIncludeProtocol') }}
              </b-form-invalid-feedback>
            </div>
          </b-card>
        </b-col>

        <!-- Password Card -->
        <b-col cols="12">
          <b-card class="mb-3" :header="$t('manage.accessPassword')">
            <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div class="mb-2 mb-md-0 mr-md-2">
                {{ urlData.access_password ? $t('manage.passwordSet') : $t('manage.noPasswordSet') }}
              </div>
              <b-button @click="toggleChangePassword" variant="primary">
                {{ urlData.access_password ? $t('manage.changePassword') : $t('manage.setPassword') }}
              </b-button>
            </div>
            <!-- Update Password Input -->
            <div v-if="showUpdatePassword" class="mt-3">
              <b-form-input
                  v-model="newPassword"
                  type="password"
                  :placeholder="$t('manage.enterNewPassword')"
                  class="mt-2"
              ></b-form-input>
              <b-form-checkbox v-model="clearPassword" class="mt-2">
                {{ $t('manage.clearPassword') }}
              </b-form-checkbox>
              <b-button
                  @click="changePassword"
                  :disabled="!newPassword && !clearPassword"
                  variant="success"
                  class="mt-2"
              >
                {{ clearPassword ? $t('manage.clearPassword') : $t('manage.submitNewPassword') }}
              </b-button>
            </div>
          </b-card>
        </b-col>

        <!-- Expiration Date Card -->
        <b-col cols="12">
          <b-card :header="$t('manage.expirationDate')" class="mb-3">
            <div class="d-flex flex-column flex-md-row justify-content-between align-items-start mb-3">
              <div>
                <strong>{{ $t('manage.currentExpirationDate') }}:</strong> {{ expirationDateDisplay }}
              </div>
              <b-button @click="toggleChangeExpirationDate" variant="primary" class="mt-2 mt-md-0">
                {{ urlData.expiration_date ? $t('manage.changeExpirationDate') : $t('manage.setExpirationDate') }}
              </b-button>
            </div>

            <b-collapse v-model="showUpdateExpirationDate">
              <b-form @submit.prevent="changeExpirationDate">
                <b-form-group :label="$t('manage.newExpirationDate')" label-for="expiration-option">
                  <b-form-radio-group
                      id="expiration-option"
                      v-model="expirationOption"
                      :options="[
              { text: $t('manage.specificDate'), value: 'specific' },
              { text: $t('manage.permanentValidity'), value: 'permanent' }
            ]"
                      stacked
                  ></b-form-radio-group>
                </b-form-group>

                <b-form-group v-if="expirationOption === 'specific'" label-for="new-expiration-date">
                  <input
                      id="new-expiration-date"
                      v-model="newExpirationDate"
                      type="datetime-local"
                      class="form-control"
                      :min="minExpirationDateTime"
                  >
                </b-form-group>

                <b-button
                    type="submit"
                    variant="success"
                    :disabled="expirationOption === 'specific' && !newExpirationDate"
                    class="mt-2"
                >
                  {{ $t('manage.submitNewExpirationDate') }}
                </b-button>
              </b-form>
            </b-collapse>
          </b-card>
        </b-col>

        <!-- GA4 Tracking Code Card -->
        <b-col cols="12">
          <GA4TrackingCodeCard
              v-model:ga4TrackingId="urlData.ga4_tracking_id"
              :manageCode="manageCode"
              @error="handleError"
              @success="handleSuccess"
              @loading="setLoading"
          />
        </b-col>
      </b-row>

      <!-- Error Message Row -->
      <b-row v-if="errorMessage">
        <b-col>
          <div class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>
        </b-col>
      </b-row>

      <!-- Success Message Row -->
      <b-row v-if="successMessage">
        <b-col>
          <div class="alert alert-success" role="alert">
            {{ successMessage }}
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- QR Code Modal -->
    <b-modal v-model="showQrCode" :title="$t('manage.showQRCode')" hide-footer>
      <QRCodeComponent :shortUrl="'https://miniurl.com/' + urlData.alias" :fullUrl="urlData.full_url"></QRCodeComponent>
    </b-modal>
  </div>
</template>

<script>
import {validateUrl} from "@/utils/tools";
import LineChart from "@/components/LineChart.vue";
import QRCodeComponent from "@/components/QRCodeComponent.vue";
import GA4TrackingCodeCard from "@/components/GA4TrackingCodeCard.vue";

export default {
  data() {
    return {
      manageCode: '',
      isLoading: false,
      newUrl: '',
      newPassword: '',
      newExpirationDate: '',
      showManagementPanel: false,
      showUpdateInput: false,
      showUpdatePassword: false,
      showUpdateExpirationDate: false,
      errorMessage: '',
      successMessage: '',
      isValidUrl: false,
      hasProtocol: false,
      urlData: {},
      clicks24Hours: [],
      clicks7Days: [],
      clicks30Days: [],
      labels24Hours: this.generateHourLabels(),
      data24Hours: {
        labels: this.generateHourLabels(),
        datasets: [{label: '', data: []}]
      },
      labels7Days: this.generateDayLabels(7, 'weekday'),
      data7Days: {
        labels: this.generateDayLabels(7, 'weekday'),
        datasets: [{label: '', data: []}]
      },
      labels30Days: this.generateDayLabels(30, 'date'),
      data30Days: {
        labels: this.generateDayLabels(30, 'date'),
        datasets: [{label: '', data: []}]
      },
      tableData: {},
      currentTab: 0,
      clearPassword: false,
      expirationOption: 'specific',
      showQrCode: false,
    };
  },
  computed: {
    getUrlValidationState() {
      if (!this.newUrl) return null;
      return this.isValidUrl && this.hasProtocol;
    },
    isManageButtonDisabled() {
      return this.manageCode.trim() === '';
    },
    totalClicks24Hours() {
      return this.clicks24Hours.reduce((sum, clicks) => sum + clicks, 0);
    },
    totalClicks7Days() {
      return this.clicks7Days.reduce((sum, clicks) => sum + clicks, 0);
    },
    totalClicks30Days() {
      return this.clicks30Days.reduce((sum, clicks) => sum + clicks, 0);
    },
    computedTableData() {
      let data;
      switch (this.currentTab) {
        case 0:
          data = this.tableData.countryClicks24Hours || [];
          break;
        case 1:
          data = this.tableData.countryClicks7Days || [];
          break;
        case 2:
          data = this.tableData.countryClicks30Days || [];
          break;
        default:
          data = [];
          break;
      }
      return data;
    },
    minExpirationDateTime() {
      const now = new Date();
      now.setMinutes(now.getMinutes() + 5); // 设置为5分钟后
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      return now.toISOString().slice(0, 16);
    },
    expirationDateDisplay() {
      if (!this.urlData.expiration_date || this.isLongTermDate(this.urlData.expiration_date)) {
        return this.$t('manage.permanentValidity');
      }
      return this.formatDateTime(this.urlData.expiration_date);
    },
  },
  watch: {
    totalClicks24Hours(newVal) {
      this.data24Hours.datasets[0].label = `${this.$t('manage.totalClicks')}: ${newVal}`;
    },
    totalClicks7Days(newVal) {
      this.data7Days.datasets[0].label = `${this.$t('manage.totalClicks')}: ${newVal}`;
    },
    totalClicks30Days(newVal) {
      this.data30Days.datasets[0].label = `${this.$t('manage.totalClicks')}: ${newVal}`;
    }
  },
  components: {
    LineChart,
    QRCodeComponent,
    GA4TrackingCodeCard,
  },
  methods: {
    checkUrl() {
      const result = validateUrl(this.newUrl);
      this.isValidUrl = result.isValid;
      this.hasProtocol = result.hasProtocol;
    },
    async manageMiniURL() {
      this.isLoading = true;
      try {
        const response = await fetch(`https://workers.miniurl.com/verifyCredentials`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({manageCode: this.manageCode})
        });
        if (response.ok) {
          const data = await response.json();
          this.urlData = data.urlData || {};
          this.clicks24Hours = data.clicks24Hours || [];
          this.clicks7Days = data.clicks7Days || [];
          this.clicks30Days = data.clicks30Days || [];

          this.data24Hours.datasets[0].data = data.clicks24Hours;
          this.data7Days.datasets[0].data = data.clicks7Days;
          this.data30Days.datasets[0].data = data.clicks30Days;

          this.tableData = {
            countryClicks24Hours: data.countryClicks24Hours || [],
            countryClicks7Days: data.countryClicks7Days || [],
            countryClicks30Days: data.countryClicks30Days || []
          };

          this.showManagementPanel = true;
          this.errorMessage = '';
        } else {
          const errorResponse = await response.json();
          this.errorMessage = errorResponse.message || this.$t('manage.unknownError');
          this.showManagementPanel = false;
        }
      } catch (error) {
        console.error(error);
        this.errorMessage = this.$t('manage.networkError');
        this.showManagementPanel = false;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteUrl() {
      if (!confirm(this.$t('manage.confirmDelete'))) return;
      this.isLoading = true;
      try {
        const response = await fetch(`https://workers.miniurl.com/deleteUrl`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({manageCode: this.manageCode})
        });
        if (response.ok) {
          this.urlData = {};
          this.showManagementPanel = false;
          this.successMessage = this.$t('manage.deleteSuccess');
        } else {
          const errorResponse = await response.json();
          this.errorMessage = errorResponse.message || this.$t('manage.deleteFailed');
        }
      } catch (error) {
        console.error(error);
        this.errorMessage = this.$t('manage.deleteNetworkError');
      } finally {
        this.isLoading = false;
      }
    },
    toggleChangeUrl() {
      this.showUpdateInput = !this.showUpdateInput;
    },
    async changeUrl() {
      if (!this.isValidUrl || !this.hasProtocol) {
        this.errorMessage = this.$t('manage.invalidUrlError');
        return;
      }
      this.isLoading = true;
      try {
        const response = await fetch(`https://workers.miniurl.com/changeUrl`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({manageCode: this.manageCode, newUrl: this.newUrl})
        });
        if (response.ok) {
          await response.json();
          this.urlData.full_url = this.newUrl;
          this.showUpdateInput = false;
          this.successMessage = this.$t('manage.changeUrlSuccess');
          this.errorMessage = '';
        } else {
          const errorResponse = await response.json();
          this.errorMessage = errorResponse.message || this.$t('manage.changeUrlFailed');
        }
      } catch (error) {
        console.error(error);
        this.errorMessage = this.$t('manage.changeUrlNetworkError');
      } finally {
        this.isLoading = false;
      }
    },
    toggleChangePassword() {
      this.showUpdatePassword = !this.showUpdatePassword;
    },
    async changePassword() {
      this.isLoading = true;
      try {
        const response = await fetch(`https://workers.miniurl.com/changePassword`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            manageCode: this.manageCode,
            newPassword: this.clearPassword ? null : this.newPassword
          })
        });
        if (response.ok) {
          await response.json();
          this.urlData.access_password = !this.clearPassword;
          this.showUpdatePassword = false;
          this.successMessage = this.clearPassword
              ? this.$t('manage.clearPasswordSuccess')
              : this.$t('manage.changePasswordSuccess');
          this.errorMessage = '';
        } else {
          const errorResponse = await response.json();
          this.errorMessage = errorResponse.message || this.$t('manage.changePasswordFailed');
        }
      } catch (error) {
        console.error(error);
        this.errorMessage = this.$t('manage.changePasswordNetworkError');
      } finally {
        this.isLoading = false;
        this.clearPassword = false;
        this.newPassword = '';
      }
    },
    toggleChangeExpirationDate() {
      this.showUpdateExpirationDate = !this.showUpdateExpirationDate;
    },

    isLongTermDate(date) {
      const longTermDate = new Date('2099-12-31T23:59:59.999Z');
      return new Date(date) >= longTermDate;
    },

    async changeExpirationDate() {
      this.isLoading = true;
      try {
        let formattedDate;
        if (this.expirationOption === 'permanent') {
          formattedDate = '2099-12-31T23:59:59.999Z';
        } else {
          const newExpirationDate = new Date(this.newExpirationDate);
          formattedDate = newExpirationDate.toISOString();
        }

        const response = await fetch(`https://workers.miniurl.com/changeExpirationDate`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({manageCode: this.manageCode, newExpirationDate: formattedDate})
        });
        if (response.ok) {
          await response.json();
          this.urlData.expiration_date = formattedDate;
          this.showUpdateExpirationDate = false;
          this.successMessage = this.$t('manage.changeExpirationDateSuccess');
          this.errorMessage = '';
        } else {
          const errorResponse = await response.json();
          this.errorMessage = errorResponse.message || this.$t('manage.changeExpirationDateFailed');
        }
      } catch (error) {
        console.error(error);
        this.errorMessage = this.$t('manage.changeExpirationDateNetworkError');
      } finally {
        this.isLoading = false;
      }
    },

    formatDateTime(date) {
      return new Date(date).toLocaleString();
    },
    generateHourLabels() {
      let currentDate = new Date();
      let currentHour = currentDate.getHours();

      return Array.from({length: 24}, (_, i) => {
        let hour = (currentHour - i + 24) % 24;
        return `${hour.toString().padStart(2, '0')}:00`;
      }).reverse();
    },
    generateDayLabels(days, format) {
      const today = new Date();
      const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return Array.from({length: days}, (_, i) => {
        const day = new Date(today);
        day.setDate(today.getDate() - (days - 1 - i));
        if (format === 'weekday') {
          return weekday[day.getDay()];
        } else {
          const month = (day.getMonth() + 1).toString().padStart(2, '0');
          const date = day.getDate().toString().padStart(2, '0');
          return `${month}-${date}`;
        }
      });
    },
    handleError(message) {
      this.errorMessage = message;
    },
    handleSuccess(message) {
      this.successMessage = message;
    },
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },
  },
};
</script>