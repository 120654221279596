// src/utils/tools.js

/**
 * Validates a URL and checks if it contains the required protocol.
 * @param {string} url The URL to validate.
 * @returns {Object} An object containing isValid and hasProtocol properties.
 */
export function validateUrl(url) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // 协议
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)*' + // 子域名
        '([a-z]{2,}|' + // 顶级域名或
        '((\\d{1,3}\\.){3}\\d{1,3})))' + // IPv4 地址
        '(\\:\\d+)?' + // 端口
        '(\\/[\\-a-z\\d%_.~+:@]*)*' + // 路径，允许冒号
        '(\\?[;&a-z\\d%_.~+=:@\\[\\]/?-]*)?' + // 查询参数，增加等号和斜杠，允许多个问号
        '(\\#[a-z\\d%_.~+=:@\\[\\]/-]*)?$', 'i'); // Fragment允许冒号和更多字符

    const isValid = pattern.test(url);
    const hasProtocol = /^(https?:\/\/)/i.test(url);
    return { isValid, hasProtocol };
}

export function formatDateTime(dateTimeString) {
    if (!dateTimeString) return 'N/A';
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };
    return new Date(dateTimeString).toLocaleString('en-US', options).replace(',', '');
}