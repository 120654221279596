<!-- UrlRedirect.vue -->
<template>
  <b-container class="mt-5">
    <div v-if="error && !requirePassword" class="alert alert-danger text-center">
      <p>{{ error }}</p>
      <p v-if="showHomeLink">
        <b-button href="/" variant="primary">{{ $t('redirect.createMiniUrl') }}</b-button>
      </p>
    </div>
    <div v-else-if="requirePassword" class="text-center">
      <h2>{{ $t('redirect.passwordProtected') }}</h2>
      <b-form @submit.prevent="submitPassword" class="mt-3">
        <b-form-group :label="$t('redirect.enterPassword')">
          <b-form-input v-model="password" type="password" required></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary">{{ $t('redirect.submit') }}</b-button>
      </b-form>
      <p v-if="error" class="text-danger mt-2">{{ error }}</p>
    </div>
    <div v-else class="text-center">
      <p v-if="passwordCorrect" class="text-success mb-2">{{ $t('redirect.passwordCorrect') }}</p>
      <div class="redirecting-container">
        <span>{{ $t('redirect.redirecting') }}</span>
        <div class="loader"></div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'UrlRedirect',
  data() {
    return {
      error: null,
      showHomeLink: false,
      requirePassword: false,
      passwordCorrect: false,
      password: '',
      path: '',
      ga4TrackingId: null,
    };
  },
  mounted() {
    this.checkAndRedirect();
  },
  methods: {
    checkAndRedirect() {
      this.path = this.$route.params.path;
      const regex = /^[A-Za-z0-9_-]{4,64}$/;
      if (regex.test(this.path)) {
        this.fetchUrlAndRedirect();
      } else {
        this.error = this.$t('redirect.errorUrlFormat');
        this.showHomeLink = true;
      }
    },
    async fetchUrlAndRedirect() {
      try {
        const response = await fetch(`https://workers.miniurl.com/${this.path}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          mode: 'cors'
        });

        const data = await response.json();

        if (response.ok) {
          this.ga4TrackingId = data.ga4TrackingId;
          this.addGA4TrackingCode(); // Add tracking code regardless of password protection
          if (data.requiresPassword) {
            this.requirePassword = true;
            this.trackPasswordPageView(); // Track password page view
          } else if (data.url) {
            this.trackRedirect(data.url); // Track redirect
            window.location.href = data.url;
          } else {
            this.error = this.$t('redirect.errorNotFound');
            this.showHomeLink = true;
          }
        } else if (response.status === 403) {
          this.error = data.error || this.$t('redirect.errorExpired');
          this.showHomeLink = true;
        } else {
          this.error = `${this.$t('redirect.errorGeneral')} ${response.status} - ${data.error || 'something went wrong'}`;
          this.showHomeLink = true;
        }
      } catch (error) {
        this.error = `${this.$t('redirect.errorGeneral')} ${error.message}`;
        this.showHomeLink = true;
      }
    },
    async submitPassword() {
      try {
        const response = await fetch('https://workers.miniurl.com/verifyPassword', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            alias: this.path,
            password: this.password
          }),
          mode: 'cors'
        });

        const data = await response.json();

        if (response.ok && data.success) {
          this.error = null;
          this.passwordCorrect = true;
          this.requirePassword = false;
          this.getUrlAfterPasswordVerification();
        } else {
          this.error = this.$t('redirect.errorIncorrectPassword');
          this.password = '';
        }
      } catch (error) {
        this.error = `${this.$t('redirect.errorGeneral')} ${error.message}`;
      }
    },
    async getUrlAfterPasswordVerification() {
      try {
        const response = await fetch(`https://workers.miniurl.com/${this.path}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.password}`
          },
          mode: 'cors'
        });

        const data = await response.json();

        if (response.ok && data.url) {
          this.trackRedirect(data.url); // Track successful password verification and redirect
          window.location.href = data.url;
        } else {
          this.error = this.$t('redirect.errorNotFound');
          this.showHomeLink = true;
          this.requirePassword = false;
          this.passwordCorrect = false;
        }
      } catch (error) {
        this.error = `${this.$t('redirect.errorGeneral')} ${error.message}`;
        this.showHomeLink = true;
        this.requirePassword = false;
        this.passwordCorrect = false;
      }
    },
    addGA4TrackingCode() {
      if (this.ga4TrackingId && !window.gtag) {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${this.ga4TrackingId}`;
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        window.gtag = function() {
          window.dataLayer.push(arguments);
        }
        window.gtag('js', new Date());
        window.gtag('config', this.ga4TrackingId);
      }
    },
    trackPasswordPageView() {
      if (window.gtag) {
        window.gtag('event', 'page_view', {
          page_title: 'Password Protected Page',
          page_location: window.location.href,
          page_path: window.location.pathname
        });
      }
    },
    trackRedirect(url) {
      if (window.gtag) {
        window.gtag('event', 'redirect', {
          event_category: 'Outbound Link',
          event_label: url,
          transport_type: 'beacon'
        });
      }
    }
  }
}
</script>

<style>
.container {
  max-width: 600px;
  margin: auto;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 0.5s linear infinite;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.redirecting-container span {
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>