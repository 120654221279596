<template>
  <div class="container d-flex flex-column align-items-center justify-content-center">
    <div class="d-flex flex-column align-items-center">
      <p class="text-center mb-2 text-break" style="max-width: 300px;">{{ shortUrl }}</p>
      <canvas ref="qrCanvas" class="mb-3" width="300" height="300"></canvas>
      <div class="mb-3">
        <div class="mb-2">
          <label for="logoOption" class="form-label">{{ $t('home.logoOption') }}</label>
          <select id="logoOption" v-model="logoOption" @change="generateQRCode" class="form-select">
            <option value="none">{{ $t('home.hideLogo') }}</option>
            <option value="site">{{ $t('home.showSiteLogo') }}</option>
            <option value="target">{{ $t('home.showTargetLogo') }}</option>
          </select>
        </div>
        <div class="mb-2">
          <label for="downloadSize" class="form-label">{{ $t('home.downloadSize') }}</label>
          <select id="downloadSize" v-model="downloadSize" class="form-select">
            <option v-for="size in downloadSizes" :key="size" :value="size">
              {{ size }}x{{ size }} {{ $t('home.pixels') }}
            </option>
          </select>
        </div>
      </div>
      <button class="btn btn-primary" @click="handleSaveQrCode" :disabled="!shortUrl">
        <i class="bi bi-download me-2"></i>{{ $t('home.saveQrCode') }} ({{ downloadSize }}x{{ downloadSize }})
      </button>
      <p v-if="showManualSaveMessage" class="text-danger mt-2">
        {{ $t('home.manualSaveMessage') }}
      </p>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode';

export default {
  props: {
    shortUrl: String,
    fullUrl: String
  },
  data() {
    return {
      logoOption: 'site',
      siteLogo: require('@/assets/logo.png'),
      targetLogo: null,
      downloadSize: 300,
      downloadSizes: [300, 400, 500, 1000],
      displaySize: 300,
      showManualSaveMessage: false
    };
  },
  methods: {
    async generateQRCode() {
      if (!this.shortUrl || !this.$refs.qrCanvas) {
        console.warn(this.$t('home.warnings.shortUrlOrCanvasUnavailable'));
        return;
      }

      const canvas = this.$refs.qrCanvas;
      const ctx = canvas.getContext('2d');

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Generate QR code
      try {
        await QRCode.toCanvas(canvas, this.shortUrl, {
          width: this.displaySize,
          margin: 1,
          color: {
            dark: '#000',
            light: '#fff'
          }
        });

        // Add logo if logoOption is not 'none'
        if (this.logoOption !== 'none') {
          const logo = new Image();
          logo.onload = () => {
            const logoSize = this.displaySize / 6;
            const padding = logoSize / 5;
            const whiteAreaSize = logoSize + 2 * padding;
            const logoX = (canvas.width - logoSize) / 2;
            const logoY = (canvas.height - logoSize) / 2;
            const whiteAreaX = (canvas.width - whiteAreaSize) / 2;
            const whiteAreaY = (canvas.height - whiteAreaSize) / 2;

            ctx.fillStyle = '#fff';
            ctx.fillRect(whiteAreaX, whiteAreaY, whiteAreaSize, whiteAreaSize);
            ctx.drawImage(logo, logoX, logoY, logoSize, logoSize);
            ctx.strokeStyle = '#fff';
            ctx.lineWidth = 2;
            ctx.strokeRect(whiteAreaX, whiteAreaY, whiteAreaSize, whiteAreaSize);
          };
          logo.src = this.logoOption === 'site' ? this.siteLogo : this.targetLogo;
        }
      } catch (error) {
        console.error(this.$t('home.errors.qrCodeGeneration'), error);
      }
    },
    async handleSaveQrCode() {
      if (!this.shortUrl) return;

      if (this.logoOption === 'target') {
        this.showManualSaveMessage = true;
        return;
      }

      this.showManualSaveMessage = false;

      // Create a new off-screen canvas for the download size
      const downloadCanvas = document.createElement('canvas');
      downloadCanvas.width = this.downloadSize;
      downloadCanvas.height = this.downloadSize;

      // Generate the QR code at the download size
      try {
        await QRCode.toCanvas(downloadCanvas, this.shortUrl, {
          width: this.downloadSize,
          margin: 1,
          color: {
            dark: '#000',
            light: '#fff'
          }
        });

        // Add logo if logoOption is 'site'
        if (this.logoOption === 'site') {
          const ctx = downloadCanvas.getContext('2d');
          const logo = new Image();
          logo.onload = () => {
            const logoSize = this.downloadSize / 6;
            const padding = logoSize / 5;
            const whiteAreaSize = logoSize + 2 * padding;
            const logoX = (downloadCanvas.width - logoSize) / 2;
            const logoY = (downloadCanvas.height - logoSize) / 2;
            const whiteAreaX = (downloadCanvas.width - whiteAreaSize) / 2;
            const whiteAreaY = (downloadCanvas.height - whiteAreaSize) / 2;

            ctx.fillStyle = '#fff';
            ctx.fillRect(whiteAreaX, whiteAreaY, whiteAreaSize, whiteAreaSize);
            ctx.drawImage(logo, logoX, logoY, logoSize, logoSize);
            ctx.strokeStyle = '#fff';
            ctx.lineWidth = 2;
            ctx.strokeRect(whiteAreaX, whiteAreaY, whiteAreaSize, whiteAreaSize);

            // Save the canvas as an image
            this.saveCanvasAsImage(downloadCanvas);
          };
          logo.src = this.siteLogo;
        } else {
          // If no logo, save the canvas as an image directly
          this.saveCanvasAsImage(downloadCanvas);
        }
      } catch (error) {
        console.error(this.$t('home.errors.qrCodeDownload'), error);
      }
    },
    saveCanvasAsImage(canvas) {
      canvas.toBlob(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${this.$t('home.fileNamePrefix')}_${this.downloadSize}x${this.downloadSize}.png`;
        link.click();
      });
    },
    async fetchTargetFavicon() {
      if (!this.fullUrl) return;

      try {
        const targetUrl = new URL(this.fullUrl);
        // Use Google's favicon service
        this.targetLogo = `https://www.google.com/s2/favicons?domain=${targetUrl.hostname}&sz=64`;
      } catch (error) {
        console.error(this.$t('home.errors.targetFavicon'), error);
        this.targetLogo = this.siteLogo;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchTargetFavicon();
      if (this.shortUrl && this.$refs.qrCanvas) {
        this.generateQRCode();
      }
    });
  },
  watch: {
    shortUrl: {
      handler(newVal) {
        this.$nextTick(() => {
          if (newVal && this.$refs.qrCanvas) {
            this.generateQRCode();
          }
        });
      },
      immediate: true
    },
    fullUrl: {
      handler() {
        this.fetchTargetFavicon();
        this.$nextTick(() => {
          if (this.shortUrl && this.$refs.qrCanvas) {
            this.generateQRCode();
          }
        });
      },
      immediate: true
    },
    logoOption() {
      this.showManualSaveMessage = false;
      this.$nextTick(() => {
        if (this.shortUrl && this.$refs.qrCanvas) {
          this.generateQRCode();
        }
      });
    }
  }
};
</script>