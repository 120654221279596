<template>
  <div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import {Chart, registerables} from 'chart.js';

export default {
  props: ['chartData'],
  mounted() {
    Chart.register(...registerables);
    this.createChart();
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.updateChart();
      }
    }
  },
  methods: {
    createChart() {
      const ctx = this.$refs.canvas.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'line',
        data: this.chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              mode: 'nearest',
              intersect: false,
              callbacks: {
                label: function (context) {
                  return 'Clicks: ' + context.parsed.y;
                }
              },
            }
          }
        }
      });
    },

    updateChart() {
      if (this.chart) {
        this.chart.data = this.chartData;
        this.chart.update();
      }
    },

  }
}
</script>
