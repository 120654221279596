<!--Terms.vue-->
<template>
  <b-container fluid="md" class="my-4">
    <b-card no-body class="rounded-3 p-3">
      <b-card-body>
        <h2 class="text-center">{{ $t('termsOfService.title') }}</h2>
        <p class="text-center"><strong>{{ $t('termsOfService.lastUpdated') }}:</strong> 2024-06-16</p>
        <h3>{{ $t('termsOfService.welcome') }}</h3>
        <p>{{ $t('termsOfService.agreementText') }} <a :href="$t('termsOfService.websiteUrl')" target="_blank">{{ $t('termsOfService.websiteUrl') }}</a> {{ $t('termsOfService.agreementContinued') }}</p>
        <h3>{{ $t('termsOfService.usingMiniURL.title') }}</h3>
        <p>{{ $t('termsOfService.usingMiniURL.description') }}</p>
        <h3>{{ $t('termsOfService.prohibitedUse.title') }}</h3>
        <ul>
          <li>{{ $t('termsOfService.prohibitedUse.item1') }}</li>
          <li>{{ $t('termsOfService.prohibitedUse.item2') }}</li>
          <li>{{ $t('termsOfService.prohibitedUse.item3') }}</li>
          <li>{{ $t('termsOfService.prohibitedUse.item4') }}</li>
        </ul>
        <h3>{{ $t('termsOfService.intellectualProperty.title') }}</h3>
        <p>{{ $t('termsOfService.intellectualProperty.description') }}</p>
        <h3>{{ $t('termsOfService.disclaimer.title') }}</h3>
        <p>{{ $t('termsOfService.disclaimer.description') }}</p>
        <h3>{{ $t('termsOfService.limitation.title') }}</h3>
        <p>{{ $t('termsOfService.limitation.description') }}</p>
        <h3>{{ $t('termsOfService.changes.title') }}</h3>
        <p>{{ $t('termsOfService.changes.description') }}</p>
        <p>{{ $t('termsOfService.contact.text') }} <a :href="'mailto:contact@miniurl.com'">{{ $t('termsOfService.contact.contactUs') }}</a>.</p>
      </b-card-body>
    </b-card>
  </b-container>
</template>