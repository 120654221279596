<!-- Auth.vue -->
<template>
  <div>
    <b-container fluid="md" class="my-4">
      <b-row class="my-3">
        <b-col cols="12" class="mt-3">
          <b-form @submit.prevent="onSubmit">
            <b-form-group>
              <b-form-input
                  id="email"
                  v-model="form.email"
                  type="email"
                  :state="emailState"
                  :placeholder="$t('auth.emailPlaceholder')"
                  @input="validateEmailOnInput"
                  @blur="validateEmailOnBlur"
                  required
              ></b-form-input>
              <b-form-invalid-feedback :state="emailState">
                {{ $t('auth.invalidEmail') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('auth.verificationCode')" label-for="verificationCode" v-if="codeSent">
              <b-form-input
                  id="verificationCode"
                  v-model="form.verificationCode"
                  type="text"
                  :placeholder="$t('auth.verificationCodePlaceholder')"
                  required
              ></b-form-input>
            </b-form-group>

            <b-button v-if="!codeSent" @click="sendVerificationCode" variant="primary" class="w-100" :disabled="isLoading || !isEmailValid">
              {{ isLoading ? $t('auth.sending') : $t('auth.sendVerificationCode') }}
            </b-button>

            <b-button v-else type="submit" variant="primary" class="w-100" :disabled="isLoading || !isEmailValid || !form.verificationCode">
              {{ isLoading ? $t('auth.processing') : $t('auth.loginOrRegister') }}
            </b-button>
          </b-form>
          <b-alert v-model="showAlert" dismissible :variant="alertVariant" class="mt-3">
            {{ alertMessage }}
          </b-alert>
        </b-col>
      </b-row>

      <!-- 添加 Google 登录按钮 -->
      <b-row class="mt-4">
        <b-col class="text-center">
          <img
              @click="initiateGoogleLogin"
              :src="require('@/assets/web_light_sq_SI.svg')"
              alt="Sign in with Google"
              class="btn btn-link p-0"
          >
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
export default {
  name: 'AuthPage',
  data() {
    return {
      form: {
        email: '',
        verificationCode: ''
      },
      codeSent: false,
      isLoading: false,
      showAlert: false,
      alertVariant: 'info',
      alertMessage: '',
      emailTouched: false,
      isEmailValid: false
    }
  },
  computed: {
    emailState() {
      if (!this.emailTouched) {
        return null;
      }
      return this.isEmailValid;
    }
  },
  methods: {
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validateEmailOnInput() {
      this.isEmailValid = this.validateEmail(this.form.email);
    },
    validateEmailOnBlur() {
      this.emailTouched = true;
      this.isEmailValid = this.validateEmail(this.form.email);
    },
    async sendVerificationCode() {
      if (!this.isEmailValid) {
        this.showAlert = true;
        this.alertVariant = 'danger';
        this.alertMessage = this.$t('auth.invalidEmail');
        return;
      }

      this.isLoading = true;
      try {
        const response = await fetch('https://workers.miniurl.com/userSendVerificationCode', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: this.form.email }),
        });
        const data = await response.json();

        if (data.success) {
          this.codeSent = true;
          this.showAlert = true;
          this.alertVariant = 'success';
          this.alertMessage = this.$t('auth.codeSent');
        } else {
          throw new Error(data.message || 'Failed to send verification code');
        }
      } catch (error) {
        this.showAlert = true;
        this.alertVariant = 'danger';
        this.alertMessage = error.message || this.$t('auth.sendCodeError');
      } finally {
        this.isLoading = false;
      }
    },
    async onSubmit() {
      if (!this.isEmailValid || !this.form.verificationCode) {
        this.showAlert = true;
        this.alertVariant = 'danger';
        this.alertMessage = this.$t('auth.allFieldsRequired');
        return;
      }

      this.isLoading = true;
      try {
        const response = await fetch('https://workers.miniurl.com/userLogin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.form),
        });
        const data = await response.json();

        if (data.success) {
          this.saveUserData(data);
          this.redirectToUserPage();
        } else {
          throw new Error(data.message || 'Authentication failed');
        }
      } catch (error) {
        this.showAlert = true;
        this.alertVariant = 'danger';
        this.alertMessage = error.message || this.$t('auth.loginError');
      } finally {
        this.isLoading = false;
      }
    },
    initiateGoogleLogin() {
      const clientId = '813009048398-4ob99j89lncvdm4k3cqdfov94p8nq84h.apps.googleusercontent.com';
      const redirectUri = `${window.location.origin}/googleauthcallback`;
      const scope = 'email profile';
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(scope)}`;
    },
    saveUserData(data) {
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('userId', data.userId);
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify({ email: this.form.email, id: data.userId }));
    },
    redirectToUserPage() {
      this.$router.push('/user').then(() => {
        window.location.reload();
      });
    }
  }
}
</script>