<!-- App.vue -->
<template>
  <div id="app">
    <b-container fluid="md">
      <b-row class="header-container">
        <b-col class="text-end">
          <a href="https://x.com/miniurl_com" target="_blank" class="me-2 text-secondary">
            <i class="bi bi-twitter-x"></i>
          </a>
          <b-dropdown id="language-dropdown" right class="m-2" variant="outline-secondary">
            <template #button-content>
              <i class="bi bi-globe"></i> {{ t('language') }}
            </template>
            <b-dropdown-item
                v-for="lang in supportedLanguages"
                :key="lang"
                @click="changeLanguage(lang)"
            >
              {{ languageNames[lang] }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <div class="logo">
        <b-link href="/" class="h1" id="logo">MiniURL.com</b-link>
      </div>
      <router-view/>
    </b-container>
    <MainNavbar v-if="!isRedirectRoute" />
  </div>
</template>

<script>
import MainNavbar from '@/components/MainNavbar.vue';
import { useRoute } from 'vue-router';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { loadLanguageAsync } from './main';

export default {
  components: {
    MainNavbar
  },
  setup() {
    const route = useRoute();
    const { locale, t } = useI18n();

    const supportedLanguages = [
      'en', 'zh', 'es', 'fr', 'de', 'ja', 'ko', 'zh_Hant', 'pt', 'it', 'ru', 'tr', 'pl', 'nl', 'vi'
    ];

    const languageNames = {
      en: 'English', zh: '简体中文', es: 'Español', fr: 'Français',
      de: 'Deutsch', ja: '日本語', ko: '한국어', zh_Hant: '繁体中文',
      pt: 'Português', it: 'Italiano', ru: 'Русский', tr: 'Türkçe',
      pl: 'Polski', nl: 'Nederlands', vi: 'Tiếng Việt'
    };

    const isRedirectRoute = computed(() => {
      return route.name === 'Redirect' || route.name === 'ShortUrlRedirect';
    });

    const changeLanguage = async (lang) => {
      await loadLanguageAsync(lang);
      locale.value = lang; // Update the locale
    };

    onMounted(() => {
      const savedLang = localStorage.getItem('userLanguage');
      if (savedLang) {
        loadLanguageAsync(savedLang);
      }
    });

    return {
      isRedirectRoute,
      changeLanguage,
      supportedLanguages,
      languageNames,
      t, // Expose t function to the template
    };
  }
}
</script>

<style>
#app {
  position: relative;
}

.header-container {
  display: flex;
  justify-content: flex-end;
}

#language-dropdown {
  align-self: flex-end;
}

.logo {
  text-align: center;
  margin-bottom: 20px;
}

#logo {
  display: inline-block;
  font-size: 48px;
  background: linear-gradient(to right, #2ecc71, #3498db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  letter-spacing: 2px;
}

/* Add some spacing between the globe icon and text */
#language-dropdown .bi-globe {
  margin-right: 5px;
}
</style>