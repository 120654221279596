<!--Report.vue-->
<template>
  <b-container fluid="md" class="my-4">
    <!-- Report Abuse Form -->
    <b-row>
      <b-col cols="12">
        <h2 class="mb-4 text-center">{{ $t('abuse.title') }}</h2>
        <b-form @submit.prevent="submitReport">
          <b-form-group :label="$t('abuse.urlLabel')" label-for="url-input">
            <b-form-input
                id="url-input"
                v-model="report.url"
                required
                :placeholder="$t('abuse.urlPlaceholder')"
            ></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('abuse.reasonLabel')" label-for="reason-select">
            <b-form-select
                id="reason-select"
                v-model="report.reason"
                :options="localizedReasons"
                required
            ></b-form-select>
          </b-form-group>

          <b-form-group :label="$t('abuse.commentsLabel')" label-for="comments-textarea">
            <b-form-textarea
                id="comments-textarea"
                v-model="report.comments"
                :placeholder="$t('abuse.commentsPlaceholder')"
            ></b-form-textarea>
          </b-form-group>

          <b-button type="submit" variant="primary">{{ $t('abuse.submitButton') }}</b-button>
        </b-form>

        <!-- Toast for message display -->
        <b-toast class="mt-2"
                 v-model="showToast"
                 :variant="message.type"
                 solid
                 toaster="b-toaster-top-right"
                 :no-fade="false"
        >
          {{ message.text }}
        </b-toast>
      </b-col>

      <b-col>
        <p class="text-muted">
          {{ $t('abuse.emailInfo') }}
          <a href="mailto:abuse@miniurl.com">abuse@miniurl.com</a>
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      report: {
        url: '',
        reason: null,
        comments: ''
      },
      message: {
        text: '',
        type: 'info'
      },
      showToast: false
    };
  },
  computed: {
    localizedReasons() {
      return [
        { value: null, text: this.$t('abuse.reasonSelect'), disabled: true },
        { value: "spam", text: this.$t('abuse.reasons.spam') },
        { value: "malware", text: this.$t('abuse.reasons.malware') },
        { value: "phishing", text: this.$t('abuse.reasons.phishing') },
        { value: "csam", text: this.$t('abuse.reasons.csam') },
        { value: "hacking", text: this.$t('abuse.reasons.hacking') },
        { value: "terrorism", text: this.$t('abuse.reasons.terrorism') },
        { value: "violence", text: this.$t('abuse.reasons.violence') },
        { value: "illegal_drugs", text: this.$t('abuse.reasons.illegal_drugs') },
        { value: "sensitive_info", text: this.$t('abuse.reasons.sensitive_info') },
        { value: "defamation", text: this.$t('abuse.reasons.defamation') },
        { value: "ip_abuse", text: this.$t('abuse.reasons.ip_abuse') },
        { value: "inappropriate", text: this.$t('abuse.reasons.inappropriate') },
        { value: "others", text: this.$t('abuse.reasons.others') }
      ];
    }
  },
  methods: {
    async submitReport() {
      try {
        const response = await fetch('https://workers.miniurl.com/reportAbuse', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.report)
        });

        const responseData = await response.json();

        if (!response.ok) {
          throw new Error(responseData.message || this.$t('abuse.submitError'));
        }

        this.message.text = responseData.message;
        this.message.type = 'success';
        this.showToast = true;
        this.resetForm();
      } catch (error) {
        this.message.text = error.message;
        this.message.type = 'danger';
        this.showToast = true;
      }
    },
    resetForm() {
      this.report.url = '';
      this.report.reason = null;
      this.report.comments = '';
    }
  }
};
</script>