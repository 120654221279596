<!-- User.vue -->
<template>
  <div class="user-page">
    <b-container fluid="md" v-if="isCheckingAuth">
      <b-row class="mb-3">
        <b-col class="text-center">
          <b-spinner variant="primary" label="Checking authentication..."></b-spinner>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid="md" v-else-if="isLoggedIn">
      <b-row class="mb-3">
        <b-col>
          <h3 class="text-center">{{ $t('user.yourUrls') }}</h3>
        </b-col>
      </b-row>

      <b-row class="mb-3 text-end">
        <b-col>
          <b-button @click="showImportModal" variant="primary">
            {{ $t('user.importUrl') }}
          </b-button>
        </b-col>
      </b-row>

      <b-row v-if="isLoading">
        <b-col class="text-center">
          <b-spinner variant="primary" label="Loading..."></b-spinner>
        </b-col>
      </b-row>

      <b-row v-else-if="userUrls.length === 0">
        <b-col class="text-center">
          <p>{{ $t('user.noUrlsFound') }}</p>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col>
          <b-table responsive hover :items="userUrls" :fields="urlFields">
            <template #cell(miniurl)="data">
              <a :href="getMiniUrl(data.item.alias)" target="_blank">{{ getMiniUrl(data.item.alias) }}</a>
            </template>
            <template #cell(full_url)="data">
              <a :href="data.item.full_url" target="_blank" :title="data.item.full_url">
                {{ truncateUrl(data.item.full_url) }}
              </a>
            </template>
            <template #cell(last_clicked)="data">
              {{ formatDateTime(data.item.last_clicked) }}
            </template>
            <template #cell(actions)="data">
              <b-button size="sm" variant="outline-primary" @click="showManagementPanel(data.item)">
                {{ $t('user.manage') }}
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col class="text-center">
          <p>{{ user.email }}</p>
          <b-button @click="logout" variant="outline-danger">
            {{ $t('user.logout') }}
          </b-button>
        </b-col>
      </b-row>

      <!-- Import Modal -->
      <b-modal id="import-modal" v-model="importModalVisible" :title="$t('user.importUrl')" @ok="importUrl" @hidden="resetImportModal">
        <b-form-group :label="$t('user.manageCode')">
          <b-form-input v-model="manageCode" required></b-form-input>
        </b-form-group>

        <!-- Error Message Row -->
        <b-row v-if="errorMessage">
          <b-col>
            <div class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
          </b-col>
        </b-row>

        <!-- Success Message Row -->
        <b-row v-if="successMessage">
          <b-col>
            <div class="alert alert-success" role="alert">
              {{ successMessage }}
            </div>
          </b-col>
        </b-row>
      </b-modal>
    </b-container>

    <AuthPage v-else @login-success="handleLoginSuccess" />

    <!-- Management Panel Modal -->
    <b-modal v-model="showManagementModal" :title="$t('user.urlManagement')" hide-footer modal-class="shadow-lg rounded" centered size="xl">
      <ManagementPanel
          :userId="user.id"
          :alias="selectedUrlAlias"
          v-if="showManagementModal"
          @url-deleted="handleUrlDeleted"
      ></ManagementPanel>
    </b-modal>

    <!-- Global Alert for messages -->
    <b-alert
        :show="showAlert"
        :variant="alertVariant"
        dismissible
        @dismissed="showAlert = false"
        class="mt-3"
    >
      {{ alertMessage }}
    </b-alert>
  </div>
</template>

<script>
import AuthPage from '@/views/Auth.vue';
import ManagementPanel from '@/components/ManagementPanel.vue';

export default {
  name: 'UserPage',
  components: {
    AuthPage,
    ManagementPanel
  },
  computed: {
    formattedUserUrls() {
      return this.userUrls.map(url => ({
        ...url,
        last_clicked: this.formatDateTime(url.last_clicked)
      }));
    }
  },
  data() {
    return {
      isCheckingAuth: true,
      isLoggedIn: false,
      user: null,
      userUrls: [],
      urlFields: [
        {key: 'miniurl', label: this.$t('user.miniurlAlias')},
        {key: 'full_url', label: this.$t('user.fullUrl')},
        {key: 'clicks', label: this.$t('user.clicks')},
        {key: 'last_clicked', label: this.$t('user.lastClicked')},
        {key: 'actions', label: this.$t('user.actions')}
      ],
      manageCode: '',
      importModalVisible: false,
      showAlert: false,
      alertMessage: '',
      alertVariant: 'info',
      errorMessage: '',
      successMessage: '',
      showManagementModal: false,
      selectedUrlAlias: '',
      token: null,
      isLoading: false,
    }
  },
  created() {
    this.checkLoginStatus();
  },
  methods: {
    async checkLoginStatus() {
      this.isCheckingAuth = true;
      this.token = localStorage.getItem('token');
      this.user = JSON.parse(localStorage.getItem('user'));
      if (this.token && this.user) {
        try {
          const response = await fetch('https://workers.miniurl.com/validateToken', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          });
          const data = await response.json();
          if (data.success) {
            this.isLoggedIn = true;
            if (data.newToken) {
              this.token = data.newToken;
              localStorage.setItem('token', data.newToken);
            }
            await this.fetchUserUrls();
          } else {
            this.logout();
          }
        } catch (error) {
          console.error('Error validating token:', error);
          this.logout();
        }
      }
      this.isCheckingAuth = false;
    },
    async fetchUserUrls() {
      this.isLoading = true;
      try {
        const response = await fetch('https://workers.miniurl.com/fetchUserUrls', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });
        const data = await response.json();
        if (response.ok && data.success) {
          this.userUrls = data.urls;
          if (data.newToken) {
            this.token = data.newToken;
            localStorage.setItem('token', data.newToken);
          }
        } else {
          this.showMessage(data.message || this.$t('user.errorFetchingUrls'), 'danger');
        }
      } catch (error) {
        console.error('Error fetching user URLs:', error);
        this.showMessage(this.$t('user.errorFetchingUrls'), 'danger');
      } finally {
        this.isLoading = false;
      }
    },
    showImportModal() {
      this.importModalVisible = true;
      this.resetImportModal();
    },
    resetImportModal() {
      this.manageCode = '';
      this.errorMessage = '';
      this.successMessage = '';
    },
    async importUrl(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (!this.manageCode) {
        this.errorMessage = this.$t('user.manageCodeRequired');
        return;
      }
      try {
        const response = await fetch('https://workers.miniurl.com/importUrl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
          },
          body: JSON.stringify({manageCode: this.manageCode})
        });
        const data = await response.json();
        if (response.ok && data.success) {
          this.successMessage = data.message || this.$t('user.urlImported');
          await this.fetchUserUrls();
          setTimeout(() => {
            this.importModalVisible = false;
          }, 500);
          if (data.newToken) {
            this.token = data.newToken;
            localStorage.setItem('token', data.newToken);
          }
        } else {
          this.errorMessage = data.message || this.$t('user.importError');
        }
      } catch (error) {
        console.error('Error importing URL:', error);
        this.errorMessage = this.$t('user.importError');
      }
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.isLoggedIn = false;
      this.user = null;
      this.userUrls = [];
      this.token = null;
      localStorage.removeItem('userId');
      this.showMessage(this.$t('user.loggedOut'), 'info');
    },
    showMessage(message, variant = 'info') {
      this.alertMessage = message;
      this.alertVariant = variant;
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 2000);
    },
    showManagementPanel(url) {
      this.selectedUrlAlias = url.alias;
      this.showManagementModal = true;
    },
    getMiniUrl(alias) {
      return `${window.location.origin}/${alias}`;
    },
    truncateUrl(url) {
      return url.length > 30 ? url.substring(0, 30) + '...' : url;
    },
    handleUrlDeleted(deletedAlias) {
      this.showManagementModal = false;
      this.userUrls = this.userUrls.filter(url => url.alias !== deletedAlias);
      this.showMessage(this.$t('user.urlDeletedSuccess'), 'success');
    },
    formatDateTime(date) {
      if (!date) return this.$t('user.neverClicked');
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      };
      return new Date(date).toLocaleString(undefined, options);
    },
    handleLoginSuccess(userData) {
      this.isLoggedIn = true;
      this.user = userData;
      this.token = userData.token;
      localStorage.setItem('token', userData.token);
      localStorage.setItem('user', JSON.stringify(userData));
      this.fetchUserUrls();
    }
  }
}
</script>