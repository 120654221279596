// src/utils/shareUtils.js

export function shareOnSocialMedia(platform, shortUrl) {
    let url = '';
    const encodedUrl = encodeURIComponent(shortUrl);
    switch (platform) {
        case 'facebook':
            url = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
            break;
        case 'x':
            url = `https://x.com/intent/tweet?url=${encodedUrl}`;
            break;
        case 'whatsapp':
            url = `https://api.whatsapp.com/send?text=Check out this awesome website: ${encodedUrl}`;
            break;
        case 'linkedin':
            url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
            break;
        case 'email':
            url = `mailto:?subject=Check this link&body=Check out this awesome website: ${shortUrl}`;
            break;
    }
    window.open(url, '_blank');
}
export async function sendManageCodeByEmail(manageCode, emailAddress, token) {
    const emailInfo = {
        manageCode: manageCode,
        email: emailAddress,
        token: token,
    };

    try {
        const response = await fetch('https://workers.miniurl.com/sendManageCode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(emailInfo)
        });

        if (!response.ok) {
            const data = await response.json();
            throw new Error('Failed to send email: ' + data);
        }

        await response.json();
        return 'Email sent successfully!';
    } catch (error) {
        console.error('Error sending email:', error);
        throw new Error('Failed to send email. Please try again.');
    }
}


export function downloadInfo(shortUrl, manageCode) {
    const element = document.createElement('a');
    const fileContent = `MiniURL: ${shortUrl}\nManage Panel: https://miniurl.com/manage\nManage Code: ${manageCode}`;
    const fileBlob = new Blob([fileContent], {type: 'text/plain'});
    element.href = URL.createObjectURL(fileBlob);
    element.download = "MiniURL_Info.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export function copyToClipboard(text) {
    return navigator.clipboard.writeText(text);
}